// Libraries
import React from 'react';

// App
import {Badge} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, JobModel} from '@supermove/models';

const JobStatusBadge = ({job}: {job: JobModel}) => (
  <Badge color={Job.getCrewPrimaryStatusColor(job)} style={{width: 150}}>
    {Job.getCrewPrimaryStatusText(job)}
  </Badge>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobStatusBadge.fragment = gql`
  ${Job.getCrewPrimaryStatusColor.fragment}
  ${Job.getCrewPrimaryStatusText.fragment}

  fragment JobStatusBadge on Job {
    id
    crewPrimaryStatus
    ...Job_getCrewPrimaryStatusColor
    ...Job_getCrewPrimaryStatusText
  }
`;

export default JobStatusBadge;
