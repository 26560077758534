// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {PaymentMethod} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import useFinishJobPostMovePaymentsMutation from '@shared/modules/Job/hooks/useFinishJobPostMovePaymentsMutation';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import useBeginPaymentV3 from '@shared/modules/Payment/hooks/useBeginPaymentV3';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Navigation from 'core/Navigation';
import PageV2 from 'modules/App/components/PageV2';
import BillPaymentsSummary from 'modules/Bill/components/BillPaymentsSummary';
import PaymentAmountModal from 'modules/Job/Customer/Project/Billing/Payment/components/PaymentAmountModal';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
  align-items: center;
`;

const SectionTitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const PartialPaymentLink = Styled.Touchable`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PartialPaymentText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
  padding-left: 8px;
`;

const Payments = Styled.View`
  align-self: center;
  align-items: center;
  width: 500px;
`;

const Button = Styled.LoadingButton`
  height: auto;
  min-height: 60px;
  width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const PaymentOptionText = Styled.Text`
  ${Typography.Mobile.Label}
  color: ${colors.white};
`;

const NoPaymentsRequiredContainer = Styled.View`
  align-items: center;
`;

const ProjectBillingNoPaymentsRequired = ({jobId}: any) => {
  const {submitting, handleSubmit} = useFinishJobPostMovePaymentsMutation({jobId});
  return (
    <NoPaymentsRequiredContainer>
      <PageV2.Title>No payment is required at this time.</PageV2.Title>
      <PageV2.Subtitle>Press 'Next' to continue.</PageV2.Subtitle>
      <Space height={40} />
      <PaymentOption name={'Next'} loading={submitting} onPress={handleSubmit} />
    </NoPaymentsRequiredContainer>
  );
};

const PaymentOption = ({loading, name, description, onPress}: any) => (
  <Button loading={loading} onPress={onPress}>
    <PaymentOptionText>{name}</PaymentOptionText>
    {!!description && <PaymentOptionText>{description}</PaymentOptionText>}
  </Button>
);

const ProjectBillingPaymentMethods = ({project, beginPaymentV3Form}: any) => {
  const {navigator, params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});
  const {paymentMethods} = project.projectType;
  const filteredPaymentMethods = _.filter(
    paymentMethods,
    (paymentMethod) =>
      isMainFlow ||
      ![
        PaymentMethodKind.INVOICE,
        PaymentMethodKind.STRIPE_SAVE_CARD,
        PaymentMethodKind.PAYENGINE_SAVE_CARD,
      ].includes(paymentMethod.kind),
  );

  return (
    <React.Fragment>
      {PaymentMethod.getSortedPaymentMethods(filteredPaymentMethods).map((paymentMethod, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Space height={16} />}
          <PaymentOption
            name={paymentMethod.name}
            description={PaymentMethod.getPaymentMethodDisplay(paymentMethod, {
              defaultValue: 'No fee',
            })}
            onPress={() => {
              const paymentMethodScreen = PaymentMethodKind.getCrewPaymentMethodScreen(
                paymentMethod.kind,
              );
              const screenProps = {
                jobUuid: params.jobUuid,
                paymentName: beginPaymentV3Form.name,
                paymentAmount: Currency.toMutation(beginPaymentV3Form.amount),
                paymentMethod: paymentMethod.kind,
                paymentMethodUuid: paymentMethod.uuid,
                paymentMethodScreen,
                step: params.step,
              };
              if (project.organization.shouldSkipTipPrompt) {
                return navigator.navigate(paymentMethodScreen, screenProps);
              }
              navigator.navigate('TipNewPaymentBillingProjectJobPage', screenProps);
            }}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const PreviousPaymentsSection = ({bill}: any) => {
  return (
    <Payments>
      <Space height={40} />
      <SectionTitle>Previous Payments</SectionTitle>
      <BillPaymentsSummary bill={bill} />
      <Space height={40} />
    </Payments>
  );
};

const ProjectBillingPaymentMethodsSection = ({project, paymentName, jobId}: any) => {
  const {params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});
  const editPaymentModal = useModal({initialIsOpen: !isMainFlow});
  const bill = project.currentPrimaryBill;
  const {balance} = project.currentAggregateBill;
  const payments = bill.payments.filter((payment: any) => payment.isPaid);
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    amount: isMainFlow ? balance : 0,
    name: paymentName,
  });
  const {form} = useBeginPaymentV3({beginPaymentV3Form});

  return (
    <Container>
      <ScrollView>
        <Space height={20} />
        <Content>
          {balance <= 0 && isMainFlow ? (
            <ProjectBillingNoPaymentsRequired jobId={jobId} />
          ) : (
            <Info>
              <PageV2.Title>Great! We're ready to accept your payment.</PageV2.Title>
              <PageV2.Subtitle>
                Please select the payment method you would like to use.
              </PageV2.Subtitle>
              <Space height={20} />
              <PaymentAmountSection
                title={'Payment Amount:'}
                amount={form.values.beginPaymentV3Form.amount}
              >
                <Space height={8} />
                <PartialPaymentLink onPress={editPaymentModal.handleOpen}>
                  <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
                  <PartialPaymentText>Edit Amount</PartialPaymentText>
                </PartialPaymentLink>
              </PaymentAmountSection>
              <Space height={20} />
              <ProjectBillingPaymentMethods
                project={project}
                beginPaymentV3Form={form.values.beginPaymentV3Form}
              />
              <Space height={20} />
            </Info>
          )}
          {payments.length > 0 && <PreviousPaymentsSection bill={bill} />}
        </Content>
      </ScrollView>
      <PaymentAmountModal
        form={form}
        paymentAmountField={'beginPaymentV3Form.amount'}
        paymentNameField={'beginPaymentV3Form.name'}
        key={editPaymentModal.isOpen}
        isOpen={editPaymentModal.isOpen}
        handleClose={editPaymentModal.handleClose}
        project={project}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingPaymentMethodsSection.fragment = gql`
  ${BillPaymentsSummary.fragment}
  ${PaymentAmountModal.fragment}
  ${PaymentMethod.getPaymentMethodDisplay.fragment}
  ${PaymentMethod.getSortedPaymentMethods.fragment}

  fragment ProjectBillingPaymentMethodsSection on Project {
    id
    currentPrimaryBill {
      id
      uuid
      customerId
      ...BillPaymentsSummary
    }
    currentAggregateBill {
      balance
    }
    organization {
      id
      shouldSkipTipPrompt
    }
    projectType {
      id
      paymentMethods {
        id
        uuid
        kind
        name
        ...PaymentMethod_getPaymentMethodDisplay
        ...PaymentMethod_getSortedPaymentMethods
      }
    }
    ...PaymentAmountModal
  }
`;

export default ProjectBillingPaymentMethodsSection;
